export const countryList = [
    {id: 1, name: 'Австралия', value: 'Australia'},
    {id: 2, name: 'Австрия', value: 'Austria'},
    {id: 3, name: 'Азербайджан', value: 'Azerbaijan'},
    {id: 4, name: 'Аргентина', value: 'Argentina'},
    {id: 5, name: 'Армения', value: 'Armenia'},
    {id: 6, name: 'Беларусь', value: 'Belarus'},
    {id: 7, name: 'Бельгия', value: 'Belgium'},
    {id: 8, name: 'Бразилия', value: 'Brazil'},
    {id: 9, name: 'Венгрия', value: 'Hungary'},
    {id: 10, name: 'Германия', value: 'Germany'},
    {id: 11, name: 'Гонконг', value: 'Hong Kong'},
    {id: 12, name: 'Грузия', value: 'Georgia'},
    {id: 13, name: 'Дания', value: 'Denmark'},
    {id: 14, name: 'Египет', value: 'Egypt'},
    {id: 15, name: 'Израиль', value: 'Israel'},
    {id: 16, name: 'Индия', value: 'India'},
    {id: 17, name: 'Ирландия', value: 'Ireland'},
    {id: 18, name: 'Испания', value: 'Spain'},
    {id: 19, name: 'Италия', value: 'Italy'},
    {id: 20, name: 'Казахстан', value: 'Kazakhstan'},
    {id: 21, name: 'Канада', value: 'Canada'},
    {id: 22, name: 'Китай', value: 'China'},
    {id: 23, name: 'Колумбия', value: 'Colombia'},
    {id: 24, name: 'Корея', value: 'Korea'},
    {id: 25, name: 'Куба', value: 'Cuba'},
    {id: 26, name: 'Мексика', value: 'Mexico'},
    {id: 27, name: 'Нидерланды', value: 'Netherlands'},
    {id: 28, name: 'Норвегия', value: 'Norway'},
    {id: 29, name: 'Польша', value: 'Poland'},
    {id: 30, name: 'Португалия', value: 'Portugal'},
    {id: 31, name: 'Россия', value: 'Russian Federation'},
    {id: 32, name: 'Сербия', value: 'Serbia'},
    {id: 33, name: 'США', value: 'USA'},
    {id: 34, name: 'Турция', value: 'Turkey'},
    {id: 35, name: 'Украина', value: 'Ukraine'},
    {id: 36, name: 'Финляндия', value: 'Finland'},
    {id: 37, name: 'Франция', value: 'France'},
    {id: 38, name: 'Хорватия', value: 'Croatia'},
    {id: 39, name: 'Чехия', value: 'Czech'},
    {id: 40, name: 'Швейцария', value: 'Switzerland'},
    {id: 41, name: 'Швеция', value: 'Sweden'},
    {id: 42, name: 'Эстония', value: 'Estonia'},
    {id: 43, name: 'Япония', value: 'Japan'},
  ];