const ENV = {
    API_URL: 'https://cinema.webcloudlab.ru/api',
    API_URL_UPLOADS_ACTORS: 'https://cinema.webcloudlab.ru/api/uploads/actors/',
    API_URL_UPLOADS_MOVIES: 'https://cinema.webcloudlab.ru/api/uploads/movies/',
    API_URL_UPLOADS_CHAT_MEDIA: 'https://cinema.webcloudlab.ru/api/uploads/conversations/',
    API_URL_UPLOADS_USERS_AVATAR: 'https://cinema.webcloudlab.ru/api/uploads/users/avatars/',
    API_URL_UPLOADS_MAIN_SLIDER: 'https://cinema.webcloudlab.ru/api/uploads/slider/main/',
    API_ACTORS: '/actors',
    API_MOVIE_ACTORS: '/movie_actors',
    API_ADD_ACTOR: '/add-actor',
    API_GET_ACTOR: '/get-actor/',
    API_MOVIES_COUNT: '/movies-count',
    API_MOVIE: '/movie/',
    API_GET_MOVIE_BY_ACTOR: '/movies-actor/',
    API_MOVIE_PROPERTIES: '/properties/',
    API_MOVIES: '/movies',
    API_LAST_MOVIES: '/last-movies',
    API_TOP_MOVIES: '/top-movies',
    API_ADD_MOVIE: '/add-movie',
    API_SEARCH_MOVIE: '/search-movie',
    API_SET_MOVIE_RATING: '/set-rating',
    API_GET_MOVIE_RATING: '/get-rating/',
    API_MOVIE_FAVORITE: '/add-favorite',
    API_MOVIES_FAVORITES: '/favorites',
    
    API_GET_MESSAGES: '/messages/',
    API_GET_MESSAGE: '/message/',
    API_GET_UNREAD_MESSAGES: '/unread-messages/',
    API_OPEN_CONVERSATION: '/open-conversation/',
    API_GET_RECIPIENT_MESSAGES: '/recipient-messages/',
    API_GET_ACTIVE_CONVERSATION: '/active-conversation/',
    API_CREATE_MESSAGE: '/create-message/',
    API_DELETE_MESSAGE: '/delete-message/',
    API_UPDATE_MESSAGE: '/update-message/',
    API_GET_CONVERSATIONS: '/get-conversations/',
    API_GET_CONVERSATION: '/get-conversation/',
    API_PIN_CONVERSATION: '/pin-conversation/',
    API_CLOSE_TICKET: '/close-ticket/',
    API_MARK_MESSAGES_READ: '/mark-message-read/',
    API_GET_CONVERSATION_MEDIA: '/conversation-media/',
    API_GET_RECIPIENT_INFO: '/recipient-info/',
    
    API_USERS: '/users',
    API_CREATE_USER: '/create-user/',
    API_AUTH: '/auth/',
    API_LOGOUT: '/logout/',
    API_VERIFY_TOKEN: '/verify-token/',
    API_REFRESH_TOKEN: '/refresh-token/',
    API_PROFILE: '/profile/',

    API_SLIDES: '/get-slides',
    API_ADD_SLIDE: '/add-slide',
    API_GET_SLIDE: '/get-slide',
    API_DELETE_SLIDE: '/delete-slide',
    API_UPDATE_SLIDE: '/update-slide',
    
    CLIENT_URL: 'https://cinema.webcloudlab.ru',
    MOVIES_URL: 'https://cinema.webcloudlab.ru/movies/',
    MOVIES: '/movies/',
    ACTORS: '/actors/',
    EDIT_SLIDER: '/admin/edit-slider/',
    ADD_MOVIE: '/admin/add-movie/',
    ADD_ACTOR: '/admin/add-actor/',
    SUPPORT_CHATS: '/admin/support-chats',
    ADMIN:'/admin/',
}
export default ENV;